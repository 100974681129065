import React, { Component } from "react";
import { connect } from "react-redux";
import { retrieveAccount, deleteAccount } from "../../actions/account";
import {
	setCurrentPage,
	setCurrentPageSize,
	setCurrentOrder,
	setCurrentOrderBy,
	setModule,
	setPeriod,
} from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import CardDataService from "../../services/card.service";
import AccountDataService from "../../services/account.service";
import { Link } from "react-router-dom";
import Link2 from "@mui/material/Link";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AllowCompany, AcIndexByValue } from "../../helpers/common";

class AccountList extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		let period;

		if (this.props.common.period) {
			period = this.props.common.period;
		} else {
			period = this.props.auth.user.id_period;
		}

		this.state = {
			id_company: this.props.auth.idCompany,
			searchName: "",
			searchCode: "",
			searchIdPeriod: period,
			alert: false,
			error: false,
			delID: 0,
			delTitle: "",
			message: false,

			companies_ready: false,
			periods_ready: false,
			companies: [],
			periods: [],
			xlsUrl: "https://fasreport.raywhite.co.id/accountlist",
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Account");
			this.getRows();
			this.loadSelect();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
		AccountDataService.getPeriod({ id_company: this.state.id_company }).then(
			(response) => {
				this.setState({
					periods_ready: true,
					periods: response.data.Row,
				});
			}
		);
	}

	onChangeIdCompany(e, val) {

		let idcd = 0;
		if (val !== null) {
			this.setState({ id_company: val.id });
			idcd=val.id;
		} else {
			this.setState({ id_company: 0 });
		}

		if(idcd > 0){
			AccountDataService.getPeriod({ id_company: idcd }).then((response) => {
				this.setState({
					periods: response.data.Row,
					searchIdPeriod: response.data.Row[0].id,
				});
			});
		}
	}

	onChangeIdperiod(e) {
		this.setState({ searchIdPeriod: e.target.value });
	}
	onChangeSearchName(e) {
		this.setState({ searchName: e.target.value });
	}
	onChangeSearchCode(e) {
		this.setState({ searchCode: e.target.value });
	}

	searchData = (e) => {
		e.preventDefault();
		this.getRows();
	};

	getRows = () => {
		this.props.showLoading();
		this.props.setPeriod(this.state.searchIdPeriod);

		var params = {
			id_company: this.state.id_company,
			name: this.state.searchName,
			code: this.state.searchCode,
			id_period: this.state.searchIdPeriod,
		};

		this.setState({ message: false }, function () {
			this.props
				.retrieveAccount(params)
				.then(() => {
					this.props.hideLoading();
				})
				.catch((e) => {
					this.setState({
						error: true,
						message: e.response.data.error,
					});
					this.props.hideLoading();
				});
		});
	};

	confirmDelete = (id, title) => {
		this.setState({
			alert: true,
			delID: id,
			delTitle: title,
		});
	};

	handleClose = (e) => {
		this.setState({
			alert: false,
			error: false,
		});
	};

	deleteRow = (e) => {
		this.props.showLoading();
		this.setState({ message: false }, function () {
			this.props
				.deleteAccount(this.state.delID, {
					id_period: this.state.searchIdPeriod,
				})
				.then((response) => {
					this.setState({ alert: false }, function () {
						this.getRows();
					});
				})
				.catch((e) => {
					console.log(e.response.data.error);
					this.props.hideLoading();
					this.setState({
						alert: false,
						error: true,
						message: e.response.data.message,
					});
				});
		});
	};

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								{AllowCompany(this.props.auth.user.usergroup) && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<Autocomplete
												options={this.state.companies}
												getOptionLabel={(option) => option.name}
												fullWidth={true}
												onChange={(e, val) => this.onChangeIdCompany(e, val)}
												value={AcIndexByValue(
													this.state.companies,
													this.state.id_company
												)}
												sx={{ minWidth: 100 }}
												renderInput={(params) => (
													<TextField
														variant="standard"
														{...params}
														label="Company"
													/>
												)}
											/>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={4}>
									<TextField
										label="Search by code"
										value={this.state.searchCode}
										onChange={(e) => this.onChangeSearchCode(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										label="Search by name"
										value={this.state.searchName}
										onChange={(e) => this.onChangeSearchName(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									{this.state.periods_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="periodsLabel">Period</InputLabel>
											<Select
												labelId="periodsLabel"
												label="Period"
												value={this.state.searchIdPeriod}
												onChange={(e) => this.onChangeIdperiod(e)}
											>
												{this.state.periods.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={6} sm={6}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
								<Grid item xs={4} sm={4} container justifyContent="flex-end">
									<Button
										variant="contained"
										component={Link}
										to={"/addaccount?id_period=" + this.state.searchIdPeriod}
										startIcon={<AddIcon />}
									>
										Create
									</Button>
								</Grid>
								<Grid item xs={2} sm={2} container justifyContent="flex-end">
									<Link2
										href={
											this.state.xlsUrl +
											"?id_company=" +
											this.state.id_company +
											"&name=" +
											this.state.searchName +
											"&code=" +
											this.state.searchCode +
											"&period=" +
											this.state.searchIdPeriod +
											"&token=" +
											this.props.auth.user.token
										}
										underline="always"
									>
										Export
									</Link2>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<TableContainer component={Paper}>
							<Table sx={{}} size="small">
								<TableHead>
									<TableRow>
										<TableCell>Code</TableCell>
										<TableCell>Name</TableCell>
										<TableCell>Type</TableCell>
										<TableCell>Class</TableCell>
										<TableCell>Balance</TableCell>
										<TableCell align="center">Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.props.account.Data &&
										this.props.account.Data.map((row, index) => (
											<TableRow
												key={index}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 },
												}}
											>
												<TableCell
													className={row.header === true ? "tblBold" : ""}
												>
													{row.code}
												</TableCell>
												<TableCell
													className={row.header === true ? "tblBold" : ""}
												>
													{row.name}
												</TableCell>
												<TableCell>{row.type}</TableCell>
												<TableCell>{row.classification}</TableCell>
												<TableCell align="right">
													<NumberFormat
														value={row.current_balance}
														displayType={"text"}
														thousandSeparator="."
														decimalSeparator=","
														decimalScale={2}
														fixedDecimalScale={true}
														prefix={""}
													/>
												</TableCell>
												<TableCell align="center">
													<Link
														sx={{ mr: "20" }}
														to={
															"/editaccount/" +
															row.id +
															"?id_period=" +
															this.state.searchIdPeriod
														}
														className="badge badge-warning"
													>
														<EditIcon fontSize="small" sx={{ fontSize: 16 }} />
													</Link>
													<DeleteIcon
														fontSize="small"
														sx={{ fontSize: 16 }}
														onClick={() => this.confirmDelete(row.id, row.name)}
													/>
												</TableCell>
											</TableRow>
										))}
								</TableBody>

								<TableFooter></TableFooter>
							</Table>
						</TableContainer>

						<Dialog
							open={this.state.alert}
							onClose={this.handleClose}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									Delete "{this.state.delTitle}" ?
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Cancel
								</Button>
								<Button onClick={this.deleteRow}>Yes</Button>
							</DialogActions>
						</Dialog>

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		common: state.common,
		loading: state.loading,
		auth: state.auth,
	};
};

export default connect(mapStateToProps, {
	retrieveAccount,
	deleteAccount,
	setCurrentPage,
	setCurrentPageSize,
	setCurrentOrder,
	setCurrentOrderBy,
	setModule,
	setPeriod,
	showLoading,
	hideLoading,
})(AccountList);
